<template>
  <div id="holdings_module">
    <div class="px-4 py-2 d-flex align-center">
      <div class="font-weight-bold primaryColor" id="holdings_module_label">Holdings</div>
      <v-progress-circular
        class="ml-2"
        indeterminate
        v-if="fundsOrHoldingsLoader"
        size="18"
        :width="2"
        color="blue"
      ></v-progress-circular>
    </div>
    <v-row class="mb-2 mx-0 mt-0 px-4">
      <v-slide-group v-model="infoType" class="pa-0" mandatory hide-arrows>
        <v-slide-item
          v-for="(item, i) in tabList"
          :key="i"
          v-slot="{ active, toggle }"
        >
          <span
            @click="
              $router
                .push({
                  path: 'holdings',
                  query: {
                    tab: infoType == 0 ? 'stocks' : 'poa',
                  },
                }).catch(() => {}) ">
            <v-btn
              depressed
              :color="
                active ? 'activeColor white--text' : 'unActiveColor black--text'
              "
              height="26"
              min-width="50"
              class="fsize12 rounded-sm text-center text-capitalize mr-2"
              :value="item.name"
              @click="toggle"
              :id="`${item.name}_menu`"
            >
              {{ item.name }}
              <span v-if="item.length != 0">&nbsp;({{ item.length }})</span>
            </v-btn>
          </span>
        </v-slide-item>
      </v-slide-group>


      <v-spacer></v-spacer>
<span class="float-right d-flex align-center" id="searchBarAndIcons" v-if="currentTableData.length > 0 && !$store.state.isMobileView">
        <span class="search-box mx-2" v-if="!$store.state.isMobileView">
          <span class="searchwhole">
             <customIcon  :name="'search'" class="searchimg" :width="'16'" :height="'16'" :color="$store.state.amogaColor"  />
            <span class="searchinput">
              <input class="placeholdsearch" placeholder="Search" id="holdings_search" autocorrect="off" v-model="searchHolding" oninput="this.value = this.value.toUpperCase()" />
            </span>
          </span>
        </span>
        <v-tooltip top color="toolTipColor toolTipTop">
          <template v-slot:activator="{ on, attrs }">
           <span v-bind="attrs" id="hold_download" v-on="on"  @click="checkDownload('holdings', 'holdings', currentTableData)">
          <customIcon  :name="'download'" class="mx-1 cursor d-flex" :width="'21'" :height="'21'" :color="$store.state.amogaColor"  />
          </span>
          </template>
          <span>Download</span>
        </v-tooltip>

        <v-tooltip top color="toolTipColor toolTipTop">
          <template v-slot:activator="{ on, attrs }">
           <span id="hold_refresh" v-bind="attrs" v-on="on" @click="$store.dispatch('holdings/getHoldings')">
          <customIcon  :name="'refresh'" class="mx-1 cursor d-flex" :width="'16'" :height="'16'" :color="$store.state.amogaColor"  />
          </span>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </span>
    </v-row>

    <v-divider class="mx-4 mb-2"></v-divider>

     <v-card class="ma-4 pa-4" v-if="  
        (currentTableData.length != 0 &&
          this.searchHolding == '' &&
          !this.fundsOrHoldingsLoader && $store.state.isMobileView && this.infoType == 0 ) ||
        (this.searchHolding !== '' &&
          holdingSearchFilter.length != 0 &&
          !this.fundsOrHoldingsLoader && $store.state.isMobileView && this.infoType == 0 )
      ">
      <div class="row ma-0 pa-0 d-flex-align-center">
        <div class="fsize12 col-6 ma-0 pa-0" id="hold_invest_label">
          Invested
        </div>
         <div class="fsize12 col-6 ma-0 pa-0" id="hold_current_label">
          Current
        </div>
      </div>
        <div class="row mx-0 mb-2 mt-0 px-0 pb-2 pt-0 d-flex-align-center border-bottom-mwSearch">
        <div class="fsize10 col-6 ma-0 pa-0 primaryColor" id="hold_invest_val">
        {{(ruppesFormat((totalInvestment).toFixed(2)))}}
        </div>
         <div class="fsize10 col-6 ma-0 pa-0 primaryColor" id="hold_current_val">
          {{ruppesFormat((totalcurrentValue).toFixed(2)) }}
        </div>
      </div>
         <div class="row ma-0 pa-0 d-flex-align-center">
        <div class="fsize10 col-6 ma-0 pa-0" id="p/l_label">
          P&L
        </div>
         <div class="fsize10 col-6 ma-0 pa-0" id="day's_p/l_label">
          Day's P&L
        </div>
      </div>
        <div class="row ma-0 pa-0 d-flex-align-center">
        <div class="col-6 ma-0 pa-0">
           <div class="fsize16 mb-1" id="p/l_val" :class="totalPnl >= 0 ? 'positiveColor' : 'negativeColor'">{{ruppesFormat(totalPnl.toFixed(2))}} <span id="p/l_val_chg" class="ml-2 fsize10" :class="totalPnlChange >= 0 ? 'positiveColor' : 'negativeColor'">{{totalPnlChange.toFixed(2)}}%</span></div>
        </div>
         <div class="col-6 ma-0 pa-0 primaryColor">
         <div id="day's_p/l_val" class=" mb-1 fsize16" :class="daysPnl >= 0 ? 'positiveColor' : 'negativeColor'">{{ruppesFormat(daysPnl.toFixed(2))}}<span id="day's_p/l_val_chg" class="ml-2 fsize10" :class="totalPnlChange >= 0 ? 'positiveColor' : 'negativeColor'">{{daysPnlChange.toFixed(2)}}%</span></div>
        </div>
      </div>
      </v-card>

    <v-card
      class="ma-4"
      v-if=" 
        (currentTableData.length != 0 &&
          this.searchHolding == '' &&
          !this.fundsOrHoldingsLoader) ||
        (this.searchHolding !== '' &&
          holdingSearchFilter.length != 0 &&
          !this.fundsOrHoldingsLoader)
      "
    >
      <v-simple-table
        id="holdings_table"
        class="border-bottom-light primaryColor overflow-y-auto"
        :fixed-header="true"
        height="auto"
        :class="{ overflowinherit: isDropped }"
        v-if="!$store.state.isMobileView"
      >
        <thead id="holdings_table_thead">
          <tr class="fixed-header tableRow" id="holdings_table_thead_tr">
            <th class="text-left d-flex align-center" v-if="infoType == 1" id="holdings_table_thead_check_box">
              <input
                type="checkbox"
                v-model="checkboxSelectAll"
                :checkecd="selectedCheckbox.length > 0"
                class="tableCheckBox"
              />
            </th>
            <th class="text-left fsize1 tableHeader" id="holdings_table_thead_Inst">Instrument</th>
            <th class="tableHeader" :class="infoType == 0 ? 'text-right' : ''" id="holdings_table_thead_qty">
              Qty.
            </th>
            <th class="text-right tableHeader" v-if="infoType == 0" id="holdings_table_thead_buy_avg">
              Buy Avg.
            </th>
            <th class="text-right tableHeader cursor" v-if="infoType == 0" id="holdings_table_thead_ltp">
                 
            <v-tooltip top color="toolTipColor toolTipTop">
          <template v-slot:activator="{ on, attrs }">
           <span v-bind="attrs" v-on="on">
             LTP
          </span>
          </template>
          <span>Last Traded Price</span>
        </v-tooltip>
            </th>
            <th class="text-right tableHeader" v-if="infoType == 0" id="holdings_table_thead_buy_value">
              Buy Value
            </th>
            <th class="text-right tableHeader" v-if="infoType == 0" id="holdings_table_thead_p&l">P&L</th>
            <th class="text-right tableHeader" v-if="infoType == 0" id="holdings_table_thead_net_chg">
              Net chg.
            </th>
          </tr>
        </thead>
        <tbody id="holdings_table_tbody"
          class="primaryColor"
          @mouseleave="
            currentIndex = -1;
            isDropped = false;
          "
        >
          <tr
            v-for="(item, index) in this.searchHolding == ''
              ? currentTableData
              : holdingSearchFilter"
            :key="index"
            class="hoverParent tableRow"
            @mouseenter="
              isDropped = false;
              currentIndex = index;
            "
            :id="`${index}_holdings_table_tbody_tr`"
          >
            <td class="px-4 py-2" style="min-height: 48px" v-if="infoType == 1" :id="`${index}_holdings_table_tbody_check_box`">
              <input
                type="checkbox"
                class="tableCheckBox"
                v-model="selectedCheckbox"
                :value="item"
              />
            </td>
            <td class="text-left px-4 py-2 pos-reletive tableContent" :id="`${index}_holdings_table_tbody_scrip_name`">
              {{
                item.scripname ? item.scripname :
                   item.ExchSeg1 == "NSE" && item.Nsetsym != "0"
                  ? item.Nsetsym
                  : item.Bsetsym
              }}
              <span class="fsize10" :id="`${index}_holdings_table_tbody_scrip_exch`">{{
                item.ExchSeg1 == "NSE" && item.Nsetsym != "0" ? item.ExchSeg1 : item.ExchSeg2
              }}</span>
              <div
                class="dropdown position-absolute right-0"
                v-if="currentIndex == index && infoType == 0"
              >
                <v-tooltip top color="toolTipColor toolTipTop">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :id="`${index}_holdings_table_tbody_opt`"
                      @mouseover="getWindowHeight(`${index}_holdings_table_tbody_opt`)"
                      @click="isDropped = !isDropped"
                      min-width="50"
                      max-width="50"
                      height="23"
                      outlined
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      class="
                        d-flex
                        align-center
                        justify-center
                        pos-reletive
                        fsize12
                        text-capitalize
                        border-0075e1
                        color-0075e1
                        rounded-sm
                        background-white
                      "
                      >More
                    </v-btn>
                  </template>
                  <span>Options</span>
                </v-tooltip>

                <transition name="slide">
                  <ul :id="`${index}_holdings_table_tbody_opt_ul`"
                    v-if="currentIndex == index && isDropped"
                    :class="isRotate ? 'bottom25' : ''"
                    class="pa-0 list expectMkWatchMoreList"
                  >
                    <li :id="`${index}_holdings_table_tbody_opt_li_exit`"
                      @click="
                        isDropped = false;
                        callOrderWindow('sell', item);
                      "
                      class="primaryColor fsize12 cursor"
                    >
                      Exit
                    </li>
                    <li :id="`${index}_holdings_table_tbody_opt_li_add`"
                      @click="
                        isDropped = false;
                        callOrderWindow('buy', item);
                      "
                      class="primaryColor fsize12 cursor"
                    >
                      Add
                    </li>
                  </ul>
                </transition>
              </div>
            </td>
            <td :id="`${index}_holdings_table_tbody_qty`"
              class="px-4 py-2 tableContent"
              :class="infoType == 0 ? 'text-right' : ''"
            >
             <span class="d-flex justify-end">
              
                <span class="pl-2 cursor d-flex align-center" style="line-height: 10px" v-if="parseInt(item.Btst) != 0 && infoType == 0"><v-tooltip top color="toolTipColor toolTipTop">
          <template v-slot:activator="{ on, attrs }">
           <span v-bind="attrs" v-on="on" >
            <span v-if="parseInt(item.Btst) != 0 && infoType == 0">
                <span v-if="parseInt(item.SellableQty) != 0"
                  >{{ item.SellableQty + " " }} </span
                >( T1: {{ item.Btst }} )</span
              >
          </span>
          </template>
          <span> <div>
            Indicates stocks that are purchased, which</div> 
            <div>are yet to be delivered in your demat account</div>
            </span>
        </v-tooltip></span> </span>

              <span v-if="parseInt(item.Btst) == 0 && infoType == 0">{{
                item.SellableQty
              }}</span>
              <span v-if="infoType == 1"> {{ item.Qty }}</span>
            </td>
            <td class="text-right px-4 py-2 tableContent" v-if="infoType == 0" :id="`${index}_holdings_table_tbody_price`">
              {{ parseFloat(item["Price"]).toFixed(2) }}
            </td>
            <td class="text-right px-4 py-2 tableContent" v-if="infoType == 0" :id="`${index}_holdings_table_tbody_ltp`">
              {{ item.ExchSeg1 == "NSE" && item.Nsetsym != "0" ? parseFloat(item.LTnse).toFixed(2) : parseFloat(item.LTbse).toFixed(2) }}
            </td>
            <td class="text-right px-4 py-2 tableContent" v-if="infoType == 0" :id="`${index}_holdings_table_tbody_buy_val`">
              {{ parseFloat(item.buyValue).toFixed(2) }}
            </td>
            <td :id="`${index}_holdings_table_tbody_pnl`"
              v-if="infoType == 0"
              class="text-right px-4 py-2 tableContent"
              :class="
                parseFloat(item.pnl) >= 0 ? 'positiveColor' : 'negativeColor'
              "
            >
              {{ ruppesFormat(item.pnl) }}
            </td>
            <td :id="`${index}_holdings_table_tbody_pnl_chg`"
              v-if="infoType == 0"
              class="text-right px-4 py-2 tableContent"
              :class="
                parseFloat(item.perChg) >= 0 ? 'positiveColor' : 'negativeColor'
              "
            >
              {{ item.perChg }}%
            </td>
          </tr>
        </tbody>
        <tfoot id="holdings_table_tfoot">
          <tr class="tableRow" v-if="infoType == 0" id="holdings_table_tfoot_tr">
            <td colspan="7">
              <v-row class="ma-0 pa-6 w-100">
                <v-col class="pa-0 tableContent">
                  <div class="secondaryColor fsize12 mb-1" id="holdings_table_tot_invest">
                    Total Investment
                  </div>
                  <div class="fsize20 primaryColor" id="holdings_table_tot_invest_val">
                    {{ ruppesFormat(totalInvestment.toFixed(2)) }}
                  </div>
                </v-col>
                <v-col class="pa-0 tableContent">
                  <div class="secondaryColor fsize12 mb-1" id="holdings_table_current_val">Current value</div>
                  <div class="fsize20" id="holdings_table_current_val">
                    {{ ruppesFormat(totalcurrentValue.toFixed(2)) }}
                  </div>
                </v-col>
                <v-col class="pa-0 tableContent">
                  <div class="secondaryColor fsize12 mb-1 d-flex align-center">
                    
                         <span class="pl-2 cursor" style="line-height: 10px"><v-tooltip top color="toolTipColor toolTipTop">
          <template v-slot:activator="{ on, attrs }">
           <span v-bind="attrs" v-on="on">
             Day's P&L
          </span>
          </template>
          <span> <div>
            Indicates the difference between last trading day's</div> 
            <div>profit & loss with today's profit & loss</div></span>
        </v-tooltip></span>
                    </div>
                  <div
                    class="fsize20 mb-1"
                    :class="daysPnl >= 0 ? 'positiveColor' : 'negativeColor'" id="holdings_table_days_pnl_val"
                  >
                    {{ ruppesFormat(daysPnl.toFixed(2)) }}
                  </div>
                  <div id="holdings_table_days_pnl_chg_val"
                    class="fsize14"
                    :class="
                      daysPnlChange >= 0 ? 'positiveColor' : 'negativeColor'
                    "
                  >
                    ( {{ daysPnlChange.toFixed(2) }}%)
                  </div>
                </v-col>
                <v-col class="pa-0 tableContent">
                  <div class="secondaryColor fsize12 mb-1" id="holdings_table_tot_pnl_label">Total P&L</div>
                  <div id="holdings_table_tot_pnl_val"
                    class="fsize20 mb-1"
                    :class="totalPnl >= 0 ? 'positiveColor' : 'negativeColor'"
                  >
                    {{ ruppesFormat(totalPnl.toFixed(2)) }}
                  </div>
                  <div id="holdings_table_tot_pnl_chg_val"
                    class="fsize14"
                    :class="
                      totalPnlChange >= 0 ? 'positiveColor' : 'negativeColor'
                    "
                  >
                    ({{ totalPnlChange.toFixed(2) }}%)
                  </div>
                </v-col>
              </v-row>
            </td>
          </tr>

          <tr id="holdings_table_tfoot_tr"
            height="50px"
            class="primaryColor tableRow"
            v-if="infoType == 1 && selectedCheckbox.length > 0"
          >
            <td class="px-4 py-2 tableContent" colspan="3">
              <v-btn id="hold_auth_btn"
                :color="$store.state.buttonThemeColor"
                depressed
                min-width="78"
                height="32"
                class="text-capitalize white--text"
                :loading="setcdslLoader"
                :disabled="setcdslLoader"
               @click="$store.dispatch('holdings/callCdsl', selectedCheckbox);cdslDialog = true"
                >{{ `Authorize (${selectedCheckbox.length})` }}</v-btn
              >
            </td>
          </tr>
        </tfoot>
      </v-simple-table>

      <div v-if="$store.state.isMobileView">
        <div
          class="row ma-0 px-3 py-2 border-bottom-mwSearch"
          v-for="(item, index) in this.searchHolding == ''
            ? currentTableData
            : holdingSearchFilter"
          :key="index"
          @click="callBottomSheet(item)"
          :id="`${index}_hold_table_div`"
        >
          <div
            class="
              row
              pa-0
              ma-0
              d-flex
              align-center
              justify-space-between
              w-100
            "
            v-if="infoType == 0"
          >
            <div class="fsize12" :id="`${index}_hold_table_qty_avg`">
              Qty.
              <span v-if="parseInt(item.Btst) != 0">
                <span v-if="parseInt(item.SellableQty) != 0"
                  >{{ item.SellableQty }} &nbsp;</span
                >( T1: {{ item.Btst }} )</span
              >
              <span v-if="parseInt(item.Btst) == 0">{{ item.SellableQty }}</span
              >| Avg. {{ parseFloat(item["Price"]).toFixed(2) }}
            </div>
            <div :id="`${index}_hold_table_perChg`"
              class="fsize12"
              :class="
                parseFloat(item.perChg) >= 0 ? 'positiveColor' : 'negativeColor'
              "
            >
              {{ item.perChg }}%
            </div>
          </div>
          <div
            class="
              row
              my-2
              mx-0
              pa-0
              d-flex
              align-center
              justify-space-between
              w-100
            "
            v-if="infoType == 0"
          >
            <div class="fsize14" :id="`${index}_hold_table_scrip_name`">
              {{ item.ExchSeg1 == "NSE" && item.Nsetsym != "0" ? item.Nsetsym : item.Bsetsym }}
              <span class="ml-1 fsize10 secondaryColor" :id="`${index}_hold_table_exch`">{{
                item.ExchSeg1 == "NSE" && item.Nsetsym != "0" ? item.ExchSeg1 : item.ExchSeg2
              }}</span>
            </div>
            <div :id="`${index}_hold_table_pnl`"
              class="fsize12"
              :class="
                parseFloat(item.pnl) >= 0 ? 'positiveColor' : 'negativeColor'
              "
            >
              {{ ruppesFormat(item.pnl) }}
            </div>
          </div>
          <div
            class="
              row
              pa-0
              ma-0
              d-flex
              align-center
              justify-space-between
              w-100
            "
            v-if="infoType == 0"
          >
            <div class="fsize12" :id="`${index}_hold_table_invest`">Invested {{ parseFloat(item["buyValue"]).toFixed(2) }}</div>
            <div class="fsize12" :id="`${index}_hold_table_ltp`">
              LTP {{ item.ExchSeg1 == "NSE" && item.Nsetsym != "0" ? parseFloat(item.LTnse).toFixed(2) : parseFloat(item.LTbse).toFixed(2) }}
            </div>
          </div>

          <div
            class="
              row
              pa-0
              ma-0
              d-flex
              align-center
              justify-space-between
              w-100
            "
            v-if="infoType == 1"
          >
            <div class="fsize14" :id="`${index}_hold_table_scrip_name`">{{ item["scripname"] }}</div>
            <div class="fsize12" :id="`${index}_hold_table_qty`">Qty {{ item.Qty }}</div>
          </div>
        </div>
      </div>


     
    </v-card>

    <div
      class="d-flex flex-column justify-center align-center vh-70"
      v-if="
        (!!currentTableData &&
          currentTableData.length == 0 &&
          this.searchHolding == '' &&
          !this.fundsOrHoldingsLoader) ||
        (!!currentTableData &&
          holdingSearchFilter.length == 0 &&
          this.searchHolding !== '' &&
          !this.fundsOrHoldingsLoader)
      "
    >
      <div>
        <img id="hold_no_data_img"
          src="../assets/images/noData.svg"
          alt=""
          class="w-300 h-170 mb-4"
        />
      </div>
      <div id="hold_no_data_label">NO HOLDINGS FOUND</div>
    </div>
    <bottomsheet
      :currentData="currentSheetData"
      v-on:from-child="getChildData"
    />

     
    <v-dialog v-model="cdslDialog" scrollable width="600px"  max-width="600px">
      <v-expand-transition>
        <v-card class="pa-5" id="authrize_dialog">
           <v-card-title> 
          <div class="d-flex align-center justify-flex-start">
            <img src="../assets/images/cdsl.svg" id="authrize_dialog_img" alt="">
            <div class="ml-2">
            <div class="fsize14" id="authrize_dialog_info_0">e-DIS Transaction Verification</div>
<div class="fsize14" id="authrize_dialog_info_1">Convenient. Dependable. Secure</div>
          </div>
          </div></v-card-title>
           <v-card-text style="height: 400px;" class="scrollModifty" id="authrize_dialog_info_action">
           <div class="d-flex rounded px-4 py-2" style="background-color: #e8f4fd;">
            <div>
             <customIcon  :name="'info'" class="mx-1 cursor d-flex" :width="'21'" :height="'21'" :color="$store.state.amogaColor"  />
            </div>
            <div>
              Disclaimer: This is electronic trade slip, which allows us to debit your Demat Holding with stocks approved by you. Through this authorization, you will be able to sell the stocks for delivery. The authorization is valid for one day only. At any point of time you can generate or reset your TPIN using the CDSL link 
              <div>
               <a href=" https://edis.cdslindia.com/home/generatepin" target="_blank" class="text-decoration-none"> https://edis.cdslindia.com/home/generatepin</a> where it will ask for your BOID and PAN. The user can always regenerate the TPIN using the above CDSL link. To change TPIN use the link 
              </div>
              <div>
                <a href="https://edis.cdslindia.com/home/changepin" target="_blank" class="text-decoration-none">https://edis.cdslindia.com/home/changepin</a>
              </div>

            </div>

          </div>
          
          <div class="">
            <v-simple-table class="">
              <thead>
                <tr>
                  <th class="text-left border-none"><span>Instrument</span></th>
                  <th class="text-right border-none"><span>Qty</span></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in this.selectedCheckbox" :key="index">

                  <td class="text-left border-none">
                    {{
                      item.scripname
                    }}

                  </td>

                  <td class="text-right border-none">
                    <span>{{ item.Qty }}</span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
           </v-card-text>
          <div class="text-right pt-4 d-flex justify-end">
               <form :action="pludgeUrl" name="frmDIS" id="contact-form" method="post" v-if="pludgeDpId">
    <input type="hidden" id="DPId" name="DPId" :value="pludgeDpId" />
    <input type="hidden" id="ReqId" name="ReqId" :value="pludgeReqId" />
    <input type="hidden" id="Version" name="Version" :value="pludgeVersion" />
    <input type="hidden" id="TransDtls" name="TransDtls" :value="pludgeTransId" />
    <input type="submit" value="Continue to CDSL" class="mr-2  white--text fsize14 rounded" style="padding: 6px 8px;background-color: #c7222a">
  </form>
            <v-btn id="auth_close_btn"
              outlined
              height="32"
              min-width="80"
              color="#9b9b9b"
              depressed
              class="fsize14 text-capitalize secondaryColor"
              @click="cdslDialog = false"
            >
              Close
            </v-btn>
          </div>
        </v-card>
      </v-expand-transition>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import commonFunc from "../mixins/commonFunctions";
import bottomsheet from "../components/bottomSheet.vue";
import customIcon from '../components/customIcon.vue'
import orderWindowjs from "../mixins/orderWindow";
export default {
  mixins: [commonFunc,orderWindowjs],
  data: () => ({
    searchHolding: "",
    isDropped: false,
    currentIndex: -1,
    isRotate: true,
    currentSheetData: [],
    selectedCheckbox:[],
  }),
  components: {
    bottomsheet,customIcon
  },
  methods: {
    getChildData(data) {
      if (data["page"] == "Holdings") {
        if (data["action"] == "Add" || data["action"] == "Exit") {
          this.callOrderWindow(
            data["action"] == "Add" ? "buy" : "sell",
            data.item
          );
        }
        if(data["action"] == "auth"){
          this.selectedCheckbox = []
          this.selectedCheckbox.push(data.item)
          this.$store.dispatch('holdings/callCdsl', this.selectedCheckbox);this.cdslDialog = true
        }
      }
    },
    callBottomSheet(val) {
      this.currentSheetData = [];
      var tempData = {
        where: "Holdings",
        isOpen: true,
        item: val,
        tab: this.infoType,
      };
      this.currentSheetData.push(tempData);
    },
    getWindowHeight(id) {
      var offsetHeight =
        this.$store.state.windowHeight -
        document.getElementById(id).getBoundingClientRect().top;
      var dropdownHeight = 100;
      offsetHeight < dropdownHeight
        ? (this.isRotate = true)
        : (this.isRotate = false);
    },

      // call order window
    async callOrderWindow(orderType, value) {
      this.$store.commit("orderWindow/setWindowLoading",true)
      this.$store.commit("orderWindow/setCurrentOrder", {
        data: value,
        page: "holdings",
      });
      this.$store.dispatch("orderWindow/setInitialValues", {
        data: value,
        page: "holdings",
      });
      orderType == "buy" ? false : true;
      this.$store.commit("orderWindow/setOrderWindow", orderType);
      this.$store.commit("orderWindow/setOrderType", orderType);
      this.changeOrderTab();
    },
  },
  computed: {
    ...mapState("holdings", [
      "holdingList",
      "tabList",
      "totalInvestment",
      "currentTableData",
      "totalcurrentValue",
      "totalPnl",
      "totalPnlChange",
      "daysPnl",
      "daysPnlChange",
      "pludgeTransId",
       "pludgeDpId",
       "pludgeReqId",
       "pludgeVersion",
       "pludgeUrl",
       "setcdslLoader"
    ]),
    ...mapState("funds", ["isEdisHave"]),
    ...mapState("settings", ["userDetails"]),
    ...mapState(["fundsOrHoldingsLoader"]),

    ...mapGetters("authentication", ["getUserId"]),
    holdingSearchFilter() {
      return this.currentTableData.filter((post) => {
        return this.infoType == 0 ? post.Nsetsym && post.Nsetsym.toLowerCase().includes(
          this.searchHolding.toLowerCase()
        ) || post.Bsetsym && post.Bsetsym.toLowerCase().includes(
          this.searchHolding.toLowerCase()
        ) : post.scripname.toLowerCase().includes(this.searchHolding.toLowerCase())
      });
    },
    infoType: {
      get() {
        return this.$store.state.holdings.infoType;
      },
      set(value) {
        this.$store.commit("holdings/setCurrentTab", value);
        this.$store.commit("holdings/setCurrentTableData", value);
        this.selectedCheckbox = [];
        localStorage.setItem("holdingsTab", JSON.stringify(value));
      },
    },
    checkboxSelectAll: {
      get: function () {
        var _checked = [];
        this.currentTableData.forEach((item) => {
          _checked.push(item);
        });
        return this.currentTableData && _checked.length > 0
          ? this.selectedCheckbox.length == _checked.length
          : false;
      },
      set: function (value) {
        var checked = [];
        if (value) {
          this.currentTableData.forEach((item) => {
            checked.push(item);
          });
        }
        this.selectedCheckbox = checked;
      },
    },
    cdslDialog: {
      get: function () {
        return this.$store.state.holdings.cdslDialog;
      },
      set: function (newValue) {
        this.$store.commit("holdings/setCdslDialog", newValue);
      },
    },
  },
  created() {
    this.$store.dispatch("holdings/getHoldings");
  },
  mounted() {
    var localTab = JSON.parse(localStorage.getItem("holdingsTab"));
    this.$route.query.tab
      ? (this.infoType = this.$route.query.tab == "stocks" ? 0 : 1)
      : (this.infoType = localTab);
  },
};
</script>